import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        user: {
            token: null,
            name: null,
            artistName: null,
            image: null,
            type: null,
        }
    }, 
    reducers: {
        setUser: (state, action)=> {
            localStorage.setItem("token", action.payload.token)
            //localStorage.setItem("user", JSON.stringify(action.payload))
            state.user = action.payload
        },
        removeUser: (state, action)=> {
            localStorage.removeItem("token");
            //localStorage.removeItem("user");
            state.user = {
                token: null,
                name: null,
                artistName: null,
                image: null,
                type: null,
            }
        },
        updateUser: (state, action)=> {
            state.user[action.payload.key] = action.payload.value
        },
    }
})

export const { setUser, removeUser, updateUser } = userSlice.actions;
export default userSlice.reducer;