import { useEffect, useState } from 'react';
import { constants } from './../Constants';
import { Icon } from '@iconify/react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useFetch from '../../useFetch';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

const Dashboard = () => {
    document.title = "Worker Dashboard | Mowing";

    let navigate = useNavigate();

    const { user } = useSelector(state => state.user);
    const [refetchTasks, setRefetchTasks] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [startedAt, setStartedAt] = useState(null);
    const [loadingFetch, setLoadingFetch] = useState(false); // Rename isLoadingFetch to loadingFetch


    useEffect(() => {
        if (!user.token) navigate('/')
    }, [user])
    const [selectedDate, setSelectedDate] = useState(new Date());

    useEffect(() => {
        const currentDate = new Date();
        setSelectedDate(currentDate);
    }, []);
    useEffect(() => {
        console.log('Updated tasks:', tasks);
    }, [tasks]);

    const formattedDate = format(selectedDate, 'yyyy-MM-dd');

    const { response, isLoadingFetch, errorFetch } = useFetch(
        `${process.env.REACT_APP_BASE_API_URL}/user-tasks?date=${formattedDate}`
    );

    useEffect(() => {
        if (response && response.tasks) {
            setTasks(response.tasks);
        }
    }, [response]);

    const convertMinutesToHours = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        if (hours > 0 && remainingMinutes > 0) {
            return `${hours} hours ${remainingMinutes} minutes`;
        } else if (hours > 0) {
            return `${hours} hours`;
        } else {
            return `${remainingMinutes} minutes`;
        }
    };

    const handleStartWork = async (pastureId) => {
        try {
            setLoadingFetch(true); // Set loading state to true while fetching

            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/pastures/${pastureId}/start-work`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                console.error('Failed to start work:', response.statusText);
                return;
            }

            const updatedPasture = await response.json();

            // Update the specific pasture's "Started at" time
            const updatedTasks = tasks.map((task) =>
                task.pasture.id === pastureId ? { ...task, pasture: { ...task.pasture, mowing_start_time: updatedPasture.pasture.mowing_start_time } } : task
            );

            console.log('Updated tasks:', updatedTasks); // Log updated tasks

            // Update the tasks state with the modified pasture data
            setTasks(updatedTasks);

        } catch (error) {
            console.error('Error while starting work:', error.message);
        } finally {
            setLoadingFetch(false); // Reset loading state
        }
    };






    return (
        <div className="container artist-details py-2">
            <div className='mb-3'>
                <h6 className="title">Welcome {user.name}!</h6>

                <p>Here is your today's assigned tasks!</p>

                <h6 className="title1">Assigned tasks:</h6>


                <p className='mb-0 pb-0'>Mowing time for the whole day:<strong> {`${response?.total_mowing_time} mins (${convertMinutesToHours(response?.total_mowing_time)})`}</strong>    </p>
                <p >Travel time for the whole day: <strong> {`${response?.total_traveling_time} mins (${convertMinutesToHours(response?.total_traveling_time)})`}</strong></p>

                <div className='point-container mt-3'>
                    <p><strong>Date:</strong> <DatePicker
                        selected={selectedDate}
                        onChange={date => setSelectedDate(date)}
                    />
                    </p>
                    {response?.tasks?.map((task, index) => (
                        <div className='d-flex mb-5' key={task.id}>

                            <div className='point-number mr-2'>{index + 1}</div>
                            <div className='ml-1'>
                                {task.pasture.completion_status === 100 ? <p className='badge badge-success mb-2' style={{ backgroundColor: 'green' }} ><strong>Completed</strong></p> : <p className='badge badge-success mb-2'>In Progress</p>}
                                <p className='mb-0'><strong>{task.pasture.address}</strong> &nbsp;  <a
                                    href={`https://www.google.com/maps/search/?api=1&query=${task.pasture.coordinates}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >View on Google Map</a></p>

                                <p className='mb-0'>Distance & time from Previous to This Location: <strong>{task.traveling_distance} - {task.traveling_time}mins</strong></p>
                                <p className='mb-0'>Surface: <strong>{task.pasture.surface}</strong></p>
                                <p className='mb-0'>Mowing Time: <strong>{`${task.mowing_time} mins (${convertMinutesToHours(task.mowing_time)})`}</strong></p>
                                <p className='mb-0'>
                                    Manager Comment: <strong>{task.pasture.manager_comments ? task.pasture.manager_comments : 'No comments from manager'}</strong>
                                </p>
                                {typeof task.pasture.images === 'string' && task.pasture.images.trim() !== '' && (() => {
                                    try {
                                        const imageArray = JSON.parse(task.pasture.images);
                                        if (Array.isArray(imageArray) && imageArray.length > 0) {
                                            return (
                                                <>
                                                    <p className='mb-0'>Pasture's Images:</p>
                                                    <div className="d-flex">
                                                        {imageArray.map((imageName, index) => (
                                                            <a
                                                                key={index}
                                                                href={`http://localhost/mowing-api/public/storage/${imageName}`}
                                                                // URL WILL BE UPDATED WHILE PUTTING ON SERVER
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <img
                                                                    src={`http://localhost/mowing-api/public/storage/${imageName}`}
                                                                    // URL WILL BE UPDATED WHILE PUTTING ON SERVER
                                                                    alt={`Image-${index}`}
                                                                    style={{ width: '100px', height: '100px', marginRight: '10px', objectFit: 'contain' }}
                                                                />
                                                            </a>
                                                        ))}
                                                    </div>
                                                </>
                                            );
                                        }
                                        return null;
                                    } catch {
                                        return null;
                                    }
                                })()}


                                <div className='mt-1'>
                                    {task.pasture.completion_status !== 1000 && (
                                        <>

                                            {/* <div>
                                                <Button
                                                    onClick={() => handleStartWork(task.pasture.id)}
                                                    className="btn-sm"
                                                    variant='primary'
                                                    disabled={loadingFetch}
                                                >
                                                    Start Work
                                                </Button>
                                            </div> */}

                                            <div>
                                                {/* <p className="badge mt-1">Started at: {task.pasture.mowing_start_time || 'Not started yet'}</p> */}
                                                <Link
                                                    role="button"
                                                    tabIndex="0"
                                                    to={`/workers/update-pasture/${task.pasture.id}`}
                                                    className="btn-sm mt-1 btn btn-success"
                                                >
                                                    Update Pasture
                                                </Link>
                                            </div>
                                        </>
                                    )}

                                </div>

                                {/* <p>Mowing time for whole day: {`${task.total_mowing_time} mins (${convertMinutesToHours(task.total_mowing_time)})`}</p>
                                <br />
                                <p>Travel time for whole day: {`${task.total_traveling_time} mins (${convertMinutesToHours(task.total_traveling_time)})`}</p> */}
                            </div>
                        </div>
                    ))}

                    <div className='d-flex mb-5'>
                        <div className='point-number mr-2 end'>End</div>
                        <div className='ml-1 d-flex align-items-center'>
                            <p className='mb-0'><strong>Return to SPO</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
